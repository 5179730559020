import React, { Component } from 'react';

import { audioLevelMap } from '../../features/base/util/webRtcStats';

const SHADOW_COLOR = '#44A5FF';

export default class MainVideoInitial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audioLevel: 0,
        };

        this.updateAudioLevel = this.updateAudioLevel.bind(this);

        this.updateInterval = null;
    }

    componentDidMount() {
        this.updateAudioLevel();
        this.updateInterval = setInterval(this.updateAudioLevel, 200);
    }

    componentWillUnmount() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
            this.updateInterval = null;
        }
    }

    updateAudioLevel() {
        const audioLevelBase = audioLevelMap.get(this.props.userId);
        if (audioLevelBase || audioLevelBase === 0) {
            const audioLevel =
                (100 * Math.log(1 + audioLevelBase)) / Math.log(2) / 100;

            // do not update the whole time..
            if (this.state.audioLevel > 0 && audioLevel < 0.03) {
                this.setState({ audioLevel: 0 });
            } else if (audioLevel >= 0.03) {
                this.setState({ audioLevel });
            }
        }
    }

    render() {
        const newSize = Math.round(5 + this.state.audioLevel * 40);
        const newSpread = Math.round(this.state.audioLevel * 5);
        const style = {
            boxShadow: `0 0 ${newSize}px ${newSpread}px ${SHADOW_COLOR}`,
        };
        return (
            <div className="main-video-placeholder-icon">
                <div className="name-initial" style={style}>
                    {this.props.initial}
                </div>
            </div>
        );
    }
}
