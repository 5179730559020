import {
    FULLSCREEN_TOGGLE,
    CHAT_NEW_MESSAGE,
    DOCUMENTS_NEW_UPLOAD,
    JOIN_MEETING,
    LEAVE_MEETING,
    ALC_KICKED,
    ALC_INITIALIZED,
    JOIN_MEETING_SUCCESS,
    ALC_MEETING_COMPLETED
} from '../features/meetings/actionTypes';
import { fullscreenChange } from '../features/meetings/actions';
import adiaLiveFx from '../features/base/util/adiaLiveFx';
import { getTranslatedString } from '../features/base/i18n/translations';

export const createHelperMiddleware = () => {
    const fullscreenChangeHandler = store => event => {
        if (document.fullscreenElement) {
            store.dispatch(fullscreenChange(true));
        } else {
            store.dispatch(fullscreenChange(false));
        }
    };
    let fullscreenChangeHandlerRef = null;

    const confirmLeaveHandler = store => event => {
        const confirmString = getTranslatedString(
            store.getState().base.i18n.language,
            'meeting'
        );
        event.returnValue = confirmString;
        return confirmString;
    };
    let confirmLeaveHandlerRef = null;

    return store => next => action => {
        switch (action.type) {
            case JOIN_MEETING_SUCCESS: {
                if (fullscreenChangeHandlerRef) {
                    document.removeEventListener(
                        'fullscreenchange',
                        fullscreenChangeHandlerRef
                    );
                }
                fullscreenChangeHandlerRef = fullscreenChangeHandler(store);
                document.addEventListener(
                    'fullscreenchange',
                    fullscreenChangeHandlerRef
                );
                return next(action);
            }
            case FULLSCREEN_TOGGLE: {
                if (
                    !document.fullscreenElement &&
                    !document.webkitFullscreenElement
                ) {
                    if (document.documentElement.requestFullscreen) {
                        document.documentElement.requestFullscreen();
                    } else if (
                        document.documentElement.webkitRequestFullscreen
                    ) {
                        document.documentElement.webkitRequestFullscreen(
                            Element.ALLOW_KEYBOARD_INPUT
                        );
                    }
                } else {
                    if (document.cancelFullScreen) {
                        document.cancelFullScreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    }
                }
                return next(action);
            }
            case CHAT_NEW_MESSAGE: {
                const state = store.getState();
                if (!state.meetings.chatPanelShown) {
                    adiaLiveFx.playMsg();
                }
                return next(action);
            }
            case DOCUMENTS_NEW_UPLOAD: {
                const state = store.getState();
                if (
                    !state.meetings.documentsPanelShown &&
                    !state.landingPage.publicServiceInfo.meetingsSettings
                        .disableCommunicatorFileTransfer
                ) {
                    adiaLiveFx.playMsg();
                }
                return next(action);
            }
            case JOIN_MEETING: {
                confirmLeaveHandlerRef = confirmLeaveHandler(store);
                window.addEventListener('beforeunload', confirmLeaveHandlerRef);
                return next(action);
            }
            case LEAVE_MEETING:
            case ALC_KICKED:
            case ALC_INITIALIZED:
            case ALC_MEETING_COMPLETED: {
                if (confirmLeaveHandlerRef) {
                    window.removeEventListener(
                        'beforeunload',
                        confirmLeaveHandlerRef
                    );
                    confirmLeaveHandlerRef = null;
                }
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
                if (fullscreenChangeHandlerRef) {
                    document.removeEventListener(
                        'fullscreenchange',
                        fullscreenChangeHandlerRef
                    );
                    fullscreenChangeHandlerRef = null;
                }
                return next(action);
            }
            default:
                return next(action);
        }
    };
};
