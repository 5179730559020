import favicon from '../assets/favicons/favicon_vz.ico';

export const Config = {
    serviceUrl: 'https://ovk-meet-int-app01.vzch.com',
    serviceId: 'VZ-DE-01',
    documentTitle: 'VZ Videoberatung',
    theme: 'vz',

    favicon,
    hideTitleResponsiveSmall: false,
    languageOverrides: {
        de: {
            meetingTitle: 'Videobesprechung',
            meetingCompleted: 'Videobesprechung beendet',
            yourMeeting: 'Ihre Videobesprechung',
            yourMeetingId: 'Ihr Zugangscode',
            enterMeetingId: 'Geben Sie Ihren erhaltenen Zugangscode an.',
            meetingId: 'Zugangscode',
            agenda: 'Besprechungsinhalt',
        },
    },
    preJoinDisclaimerEnabled: true,
    preJoinDisclaimerContent: {
        de:
            'Es wird der Ton (nicht jedoch das Bild) Ihres Videoberatungsgesprächs aufgezeichnet. Die Aufzeichnung dient Qualitätssicherungs- und Nachweiszwecken sowie im Falle einer Anlageberatung oder der Entgegenahme von Aufträgen der Erfüllung von gesetzlichen Anforderungen. Sie gelangen nur durch die Bestätigung in den virtuellen Besprechungsraum. Falls Sie damit nicht einverstanden sein sollten, kontaktieren Sie bitte Ihren Berater.',
        en:
            'Es wird der Ton (nicht jedoch das Bild) Ihres Videoberatungsgesprächs aufgezeichnet. Die Aufzeichnung dient Qualitätssicherungs- und Nachweiszwecken sowie im Falle einer Anlageberatung oder der Entgegenahme von Aufträgen der Erfüllung von gesetzlichen Anforderungen. Sie gelangen nur durch die Bestätigung in den virtuellen Besprechungsraum. Falls Sie damit nicht einverstanden sein sollten, kontaktieren Sie bitte Ihren Berater.',
        fr:
            'Es wird der Ton (nicht jedoch das Bild) Ihres Videoberatungsgesprächs aufgezeichnet. Die Aufzeichnung dient Qualitätssicherungs- und Nachweiszwecken sowie im Falle einer Anlageberatung oder der Entgegenahme von Aufträgen der Erfüllung von gesetzlichen Anforderungen. Sie gelangen nur durch die Bestätigung in den virtuellen Besprechungsraum. Falls Sie damit nicht einverstanden sein sollten, kontaktieren Sie bitte Ihren Berater.',
        it:
            'Es wird der Ton (nicht jedoch das Bild) Ihres Videoberatungsgesprächs aufgezeichnet. Die Aufzeichnung dient Qualitätssicherungs- und Nachweiszwecken sowie im Falle einer Anlageberatung oder der Entgegenahme von Aufträgen der Erfüllung von gesetzlichen Anforderungen. Sie gelangen nur durch die Bestätigung in den virtuellen Besprechungsraum. Falls Sie damit nicht einverstanden sein sollten, kontaktieren Sie bitte Ihren Berater.',
    },
    preJoinDisclaimerLabel: {
        de: 'Ich bin damit einverstanden.',
        en: 'Ich bin damit einverstanden.',
        fr: 'Ich bin damit einverstanden.',
        it: 'Ich bin damit einverstanden.',
    },
};
