import React, { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import classNames from 'classnames';

import MeetingMainVideo from './MeetingMainVideo';
import MeetingVideoList from './MeetingVideoList';
import { LoadingScreen } from '../base/loadingScreen/LoadingScreen';
import MeetingToolbar from './MeetingToolbar';
import MeetingHeader from './RecordingIndicator';
import SharedApplicationsPanel from './SharedApplicationsPanel';
import ChatDocumentsPanel from './ChatDocumentsPanel';
import MeetingTopbar from './MeetingTopbar';

import {
    joinMeeting,
    showControls,
    hideControls,
    alcConfirmRecording,
} from './actions';
import {
    APP_STATE,
    MEETING_TYPES,
    RECORDING_CONFIRMATION_STATE,
    RESPONSIVE_MODES,
} from '../../constants/constants';
import SpeakerConfirmationDialog from './SpeakerConfirmationDialog';
import TranslatedString from '../base/i18n/TranslatedString';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { getTranslatedString } from '../base/i18n/translations';

export class Meeting extends Component {
    constructor(props) {
        super(props);
        this._showToolbarTimeout = null;
        this.handleMouseEvent = this.handleMouseEvent.bind(this);
        this.handleMouseEventThrottled = throttle(this.handleMouseEvent, 100);
    }

    componentDidMount() {
        window.addEventListener('mousemove', this.handleMouseEventThrottled);
        window.addEventListener('touchmove', this.handleMouseEventThrottled);
        window.addEventListener('touchstart', this.handleMouseEventThrottled);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMouseEventThrottled);
        window.removeEventListener('touchmove', this.handleMouseEventThrottled);
        window.removeEventListener(
            'touchstart',
            this.handleMouseEventThrottled
        );
    }

    handleMouseEvent() {
        if (!this.props.controlsVisible) {
            this.props.showControls();
        }
        if (this._showToolbarTimeout) {
            clearTimeout(this._showToolbarTimeout);
            this._showToolbarTimeout = null;
        }
        // TODO: remove check for webinar when handled better
        if (this.props.meetingType !== MEETING_TYPES.WEBINAR) {
            this._showToolbarTimeout = setTimeout(() => {
                if (!this.props.menuVisible) {
                    this.props.hideControls();
                    this._showToolbarTimeout = null;
                } else {
                    this.handleMouseEvent();
                }
            }, 5000);
        }
    }

    render() {
        let confirmRecordingDialog = null;
        if (this.props.confirmRecordingDialogVisible) {
            const footer = (
                <div>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'decline'
                        )}
                        className="p-button-secondary"
                        icon="pi pi-times"
                        onClick={() => this.props.alcConfirmRecording(false)}
                    />
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'confirm'
                        )}
                        className="p-button-primary"
                        icon="pi pi-check"
                        onClick={() => this.props.alcConfirmRecording(true)}
                    />
                </div>
            );

            confirmRecordingDialog = (
                <Dialog
                    header={<TranslatedString id={'recConfirmTitle'} />}
                    footer={footer}
                    visible={this.props.confirmRecordingDialogVisible}
                    onHide={() => {}}
                    closable={false}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    <TranslatedString id="recConfirmMessage" />
                </Dialog>
            );
        }

        if (this.props.alcMeetingJoined && this.props.webRtcMeetingJoined) {
            return (
                <div className="meeting-wrapper">
                    <div
                        className={classNames('meeting', {
                            'videolist-active':
                                this.props.responsiveMode >
                                RESPONSIVE_MODES.SMALL,
                            'responsive-mode-small':
                                this.props.responsiveMode ===
                                RESPONSIVE_MODES.SMALL,
                            'responsive-mode-medium':
                                this.props.responsiveMode ===
                                RESPONSIVE_MODES.MEDIUM,
                            'responsive-mode-full':
                                this.props.responsiveMode ===
                                RESPONSIVE_MODES.FULL,
                        })}
                    >
                        <MeetingMainVideo />
                        <MeetingHeader />
                        <MeetingTopbar />
                        <MeetingToolbar />
                        <MeetingVideoList />

                        {this.props.sharedApplicationsUrl && (
                            <React.Fragment>
                                {this.props.chatDocumentsIFrameInitialized && (
                                    <SharedApplicationsPanel />
                                )}
                                <ChatDocumentsPanel />
                            </React.Fragment>
                        )}
                        <SpeakerConfirmationDialog />
                        {confirmRecordingDialog}
                    </div>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <LoadingScreen />
                    {confirmRecordingDialog}
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = (state) => {
    let confirmRecordingDialogVisible = false;
    if (
        (state.meetings.alcMeetingJoined &&
            state.meetings.meetingInfo.recordingConfirmationInProgress &&
            state.meetings.clientInfo.recordingConfirmationState ===
                RECORDING_CONFIRMATION_STATE.PENDING) ||
        state.landingPage.appState === APP_STATE.RECORDING_CONFIRMATION
    ) {
        confirmRecordingDialogVisible = true;
    }

    return {
        alcMeetingJoined: state.meetings.alcMeetingJoined,
        webRtcMeetingJoined: state.meetings.webRtcMeetingJoined,
        controlsVisible: state.meetings.controlsVisible,
        sharedApplicationsUrl:
            state.landingPage.publicServiceInfo.meetingsSettings
                .sharedApplicationsUrl,
        responsiveMode: state.base.common.responsiveMode,
        menuVisible: state.meetings.menuVisible,
        chatDocumentsIFrameInitialized:
            state.meetings.chatDocumentsIFrameInitialized,
        meetingType:
            state.meetings.meetingInfo && state.meetings.meetingInfo.type,
        confirmRecordingDialogVisible,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    joinMeeting,
    showControls,
    hideControls,
    alcConfirmRecording,
};

export default connect(mapStateToProps, mapDispatchToProps)(Meeting);
