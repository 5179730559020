import * as types from './actionTypes';
import {
    ALC_ERROR,
    JOIN_MEETING,
    JOIN_MEETING_SUCCESS,
} from '../meetings/actionTypes';
import { APP_STATE } from '../../constants/constants';
import {
    CONTACT_FORM_REQUEST,
    CONTACT_FORM_REQUEST_FAILURE,
    CONTACT_FORM_REQUEST_SUCCESS,
} from './actionTypes';

export const initialState = {
    appState: APP_STATE.LOADING,

    publicServiceInfo: undefined,
    publicServiceIsLoading: false,

    meetingId: undefined,
    meetingInfo: undefined,
    participantInfo: undefined,
    meetingInfoIsLoading: false,
    serverTimeDiff: 0,

    errorId: undefined,
    errorNo: undefined,
    errorData: undefined,

    contactFormIsLoading: false,
    contactFormSuccess: false,
    contactFormErrorId: undefined,
};

export default function landingPage(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_APP_STATE: {
            return {
                ...state,
                appState: action.appState,
                errorId: undefined,
                errorNo: undefined,
                errorData: undefined,
            };
        }
        case types.EDIT_PARTICIPANT: {
            return {
                ...state,
                participantInfo: undefined,
                meetingId: state.meetingInfo._id,
            };
        }
        case types.NEW_ERROR: {
            return {
                ...state,
                errorId: action.errorId,
                errorNo: action.errorNo,
                errorData: action.errorData,
            };
        }
        case types.PUBLIC_SERVICE_INFO_REQUEST: {
            return {
                ...state,
                publicServiceIsLoading: true,
            };
        }
        case types.PUBLIC_SERVICE_INFO_SUCCESS: {
            return {
                ...state,
                publicServiceIsLoading: false,
                publicServiceInfo: action.publicServiceInfo,
            };
        }
        case types.PUBLIC_SERVICE_INFO_FAILURE: {
            return {
                ...state,
                publicServiceIsLoading: false,
            };
        }
        case types.ALC_GET_MEETING_INFO: {
            return {
                ...state,
                meetingInfoIsLoading: true,
                errorId: undefined,
                errorNo: undefined,
                errorData: undefined,
                meetingInfoErrorMessage: undefined,
            };
        }
        case types.ALC_GET_MEETING_INFO_SUCCESS: {
            const meetingId = action.participantInfo
                ? action.participantInfo._id
                : action.meetingInfo._id;

            return {
                ...state,
                meetingInfoIsLoading: false,
                meetingInfo: action.meetingInfo,
                participantInfo: action.participantInfo,
                serverTimeDiff: action.serverTime
                    ? action.serverTime - Date.now()
                    : 0,
                meetingId,
                appState: APP_STATE.DISPLAY_MEETING_INFO,
            };
        }
        case JOIN_MEETING: {
            return {
                ...state,
                appState: APP_STATE.JOINING_MEETING,
            };
        }
        case ALC_ERROR: {
            if (
                action.context === 'joinMeeting' &&
                action.error.errorNo === 40104
            ) {
                return {
                    ...state,
                    appState: APP_STATE.RECORDING_CONFIRMATION,
                };
            } else {
                return state;
            }
        }
        case JOIN_MEETING_SUCCESS: {
            return {
                ...state,
                appState: APP_STATE.IN_MEETING,
            };
        }
        case CONTACT_FORM_REQUEST: {
            return {
                ...state,
                contactFormIsLoading: true,
            };
        }
        case CONTACT_FORM_REQUEST_SUCCESS: {
            return {
                ...state,
                contactFormIsLoading: false,
                contactFormSuccess: true,
            };
        }
        case CONTACT_FORM_REQUEST_FAILURE: {
            return {
                ...state,
                contactFormIsLoading: false,
                contactFormSuccess: false,
                contactFormErrorId: action.errorId,
            };
        }
        default: {
            return state;
        }
    }
}
