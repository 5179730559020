import React from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import TranslatedString from '../base/i18n/TranslatedString';

import { hideSpeakerConfirmation, revokeSpeakerPermission } from './actions';
import { webRtcChangeCommunicationMode } from '../deviceSettings/actions';
import { getTranslatedString } from '../base/i18n/translations';
import { COMMUNICATION_MODES } from '../../constants/constants';

export function SpeakerConfirmationDialog(props) {
    const footer = (
        <div>
            <Button
                label={getTranslatedString(props.language, 'cancel')}
                className="p-button-secondary"
                icon="pi pi-times"
                onClick={props.revokeSpeakerPermission}
            />
        </div>
    );

    return (
        <Dialog
            className="speaker-confirmation-dialog"
            header={<TranslatedString id={'speakerPermission'} />}
            footer={footer}
            visible={props.speakerConfirmationVisible}
            closable={false}
            onHide={() => {}}
            closeOnEscape={false}
            baseZIndex={999999}
            focusOnShow={false}
        >
            <div className="p-grid">
                <div className="p-col-12">
                    <TranslatedString id={'speakerPermissionConfirmation'} />
                </div>
                <div className="p-col-12">
                    <Button
                        label={getTranslatedString(
                            props.language,
                            'comModeAudio'
                        )}
                        className="p-button-primary"
                        onClick={() => {
                            props.hideSpeakerConfirmation();
                            props.webRtcChangeCommunicationMode(
                                COMMUNICATION_MODES.AUDIO
                            );
                        }}
                    />
                    {!props.disableVideoForParticipants && (
                        <Button
                            label={getTranslatedString(
                                props.language,
                                'comModeBoth'
                            )}
                            className="p-button-primary"
                            onClick={() => {
                                props.hideSpeakerConfirmation();
                                props.webRtcChangeCommunicationMode(
                                    COMMUNICATION_MODES.BOTH
                                );
                            }}
                        />
                    )}
                </div>
            </div>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        speakerConfirmationVisible: state.meetings.speakerConfirmationVisible,
        disableVideoForParticipants:
            state.landingPage.publicServiceInfo.webinarSettings &&
            state.landingPage.publicServiceInfo.webinarSettings
                .disableVideoForParticipants,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    hideSpeakerConfirmation,
    revokeSpeakerPermission,
    webRtcChangeCommunicationMode,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpeakerConfirmationDialog);
