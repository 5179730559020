export const italian = {
    // General
    language: 'Lingua',
    de: 'Tedesco',
    en: 'Inglese',
    fr: 'Francese',
    it: 'Italiano',

    save: 'Salva',
    cancel: 'Annulla',
    confirm: 'Conferma',
    decline: 'Respingere',
    close: 'Chiudi',
    refresh: 'Ricaricare',

    reconnectingHeader: 'Connessione persa',
    reconnectingMessage:
        'Connessione al server persa. La connessione viene ripristinata...',

    // Device Settings

    deviceSettings: 'Impostazioni',
    cam: 'Videocamera',
    mic: 'Mircrofono',
    resolution: 'Risoluzione',
    comModeNone: 'Solo chat',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + video',

    // Meeting

    agenda: 'Ordine del giorno',
    showboard: 'Showboard',
    minutes: 'Rapporto',
    documents: 'Documenti',
    chat: 'Chat',
    show: 'Mostra',

    startFullscreen: 'Apri schermo intero',
    stopFullscreen: 'Chiudi schermo intero',
    startScreenShare: 'Condividi lo schermo',
    stopScreenShare: 'Annulla condivisione schermo',
    leaveMeeting: 'Abbandona il Meeting',
    muteMic: 'Disabilita il miscrofono',
    unmuteMic: 'Abilita il miscrofono',
    activateMic: 'Attiva il microfono',
    startCam: 'Abilita videocamera',
    stopCam: 'Disabilita videocamera',
    activateCam: 'Attiva videocamera',
    meetingInfo: 'Info Meeting',
    sharedApplications: 'Shared Applications',
    menu: 'Menu',

    msgRemoteMute: 'Un consulente ha disabilitato il suo microfono.',
    msgKick: 'Un consulente vi ha rimosso dal Meeting.',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Recording
    recConfirmTitle: 'Acconsentire la registrazione',
    recConfirmMessage: "Vorremmo registrare questo meeting. È d'accordo",

    // Errors

    connectionError: 'La connessione al server non riuscita.',
    serviceNotFound: 'Questo Service non esiste.',

    alcErrorDefault: 'Errore alc',
    alcErrorMeetingDoesNotExist:
        'Il Meeting già stato completato o non esiste.',
    alcErrorMeetingConnectionLost:
        'Connessione al Meeting-Server persa. Vi preghiamo di riprovare',
    alcErrorMeetingFull:
        'È stato superato il limite massimo di partecipanti al Meeting.',
    alcErrorWebinarParticipantsFull:
        'Numero massimo di partecipanti raggiunto per questo webinar.',
    alcErrorNeedsRecordingConfirmation:
        'Può partecipare a questa riunione solo se accetta di essere registrato.',

    webRtcErrorDefault: 'Errore WebRTC',
    webRtcErrorNotFound:
        'Videocamera/microfono non trovati. Verificare che la videocamera o il microfono siano collegati dopodichè cliccare Ricaricare.',
    webRtcErrorNotAllowed:
        "Consentire l'accesso alla microfono / videocamera nelle impostazioni del browser. Attivi successivamente l'audio o l'audio + video.",
    webRtcErrorNotReadable:
        "La sua videocamera è bloccata da un'altra applicazione. Per favore chiuda l'applicazioneen o selezioni un'altra videocamera.",
    webRtcErrorOverconstrained:
        'La sua videocamera salvata / Il suo microfono salvato non sono stati trovati. Verificare che la videocamera o il microfono siano collegati dopodichè cliccare Ricaricare o selezioni un altro dispositivo.',
    webRtcErrorConnectionTimeout:
        'Timeout nel tentativo di connettersi al Meeting server',

    webRtcBrowserNotSupported:
        'Il tuo browser non è supportato. Utilizza una versione recente di Chrome/Firefox o Safari su iOS.',
    webRtcBrowserOutOfDate:
        'La vostra versione di {{browser}} è obsoleta e non è più supportata. Per favore aggiorna il tuo browser.',
    webRtcBrowserNonSafariIos:
        'Sfortunatamente {{browser}} non è supportata su iOS. Per favore utilizzi una versione recente di Safari',
    webRtcBrowserLegacyEdge:
        'State usando Edge Legacy. Questa versione non è aggiornata e non è più supportata. Vi consigliamo di utilizzare la nuova versione Edge che potete scaricare {{link}}.',
    webRtcBrowserIe:
        'Internet Explorer non è supportato. Vi consigliamo di utilizzare la nuova versione di Edge che potete scaricare {{link}}.',

    // Communicator

    yourName: 'Il suo nome',
    yourEmail: 'Il suo indirizzo e-mail',
    invalidEmail: 'Indirizzo e-mail non valido.',
    enterNameAndEmail: "Inserisca il suo nome e l'indirizzo e-mail.",
    continue: 'Continua',

    meetingTitle: 'Meeting',
    meetingCompleted: 'Meeting concluso',
    yourMeetingId: 'Il suo Meeting-ID',
    yourMeeting: 'Il suo Meeting',
    enterMeetingId: 'Immetta il Meeting-ID ricevuto.',
    meetingId: 'Meeting-ID',
    joinMeeting: 'Partecipare ora',
    meetingNotReady:
        'Il suo appuntamento avrà luogo il {{date}} all {{time}}. Prego attendere o riprova più tardi',
    meetingExpired:
        "L'appuntamento non è più valido. La peghiamo di fissare un nuovo appuntamento.",

    webinarTitle: 'Webinar',
    webinarCompleted: 'Webinar concluso',
    yourWebinarId: 'Il suo ID Webinar',
    yourWebinar: 'Il vostro webinar',
    enterWebinarId: 'Inserisca il suo ID Webinar',
    webinarId: 'ID Webinar',
    joinWebinar: 'Partecipare ora',
    webinarNotReady:
        'Il webinar sie svolgerà il {{date}} alle {{time}}. Attendete o riprovate più tardi',
    webinarExpired: 'Il webinar non è più valido',

    consultationTitle: 'Consulenza',
    consultationCompleted: 'Consulenza completata',
    yourConsultationId: 'Il suo ID consulenza',
    yourConsultation: 'La sua consulenza',
    enterConsultationId: 'Inserisca il suo ID consulenza',
    consultationId: 'ID consulenza',
    joinConsultation: 'Partecipi ora',
    consultationNotReady:
        'La consulenza si svolgerà il {{date}} alle {{time}}. Attenda o riprovi più tardi.',
    consultationExpired: 'Questa consulenza non è più valida.',

    //Webinars
    participants: 'Partecipanti',
    requestSpeakerPermission: 'Richiedere permesso per parlare',
    cancelRequestSpeakerPermission: 'Annulalre richiesta per parlare',
    endSpeakerPermission: 'Terminare il permesso per parlare',
    speakerPermission: 'Permesso per parlare',
    speakerPermissionConfirmation:
        'Vi è stato concesso il permesso per parlare. Scelga il supporto di comunicazione desiderato.',
    leaveWebinar: 'Abbandona il Webinar',

    // Contact Form
    contactForm: 'Richiesta di contatto',
    contactFormText: 'Oppure ci invii le sue informazioni di contatto',
    contactFormSuccess:
        'Grazie per averci contattato. Abbiamo ricevuto le sue informazioni di contatto e la contatteremo.',
    send: 'Invia',
    name: 'Nome',
    email: 'E-mail',
    phone: 'Telefono',
    message: 'Messaggio',
    desiredDate: 'Data desiderata',
};
