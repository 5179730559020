import { take, put, all } from 'redux-saga/effects';

import { Config } from '../../config/Config';
import { APP_STATE } from '../../constants/constants';

import {
    ALC_GET_MEETING_INFO_SUCCESS,
    CONFIRM_DISCLAIMER,
    GET_MEETING_INFO,
    INIT_APP,
    PUBLIC_SERVICE_INFO_FAILURE,
    PUBLIC_SERVICE_INFO_SUCCESS,
} from './actionTypes';
import {
    ALC_ERROR,
    ALC_INITIALIZED,
    WEBRTC_INIT_FAILURE,
    WEBRTC_INIT_SUCCESS,
} from '../meetings/actionTypes';
import {
    requestPublicServiceInfo,
    getMeetingInfo,
    changeAppState,
    newError,
    alcGetMeetingInfo,
} from './actions';
import { webRtcInit, alcInit } from '../meetings/actions';
import {
    alcErrorCodeToErrorId,
    webRtcErrorCodeToErrorId,
} from '../base/util/helpers';

function* initAppSaga() {
    while (true) {
        yield take(INIT_APP);
        let serviceId;
        if (Config.serviceIdFromUrl) {
            serviceId = window.location.pathname
                .split('/')
                .filter((item) => item)
                .pop();
        } else {
            serviceId = Config.serviceId;
        }
        if (!serviceId) {
            yield put(changeAppState(APP_STATE.INIT_FAILURE));
            yield put(newError('serviceNotFound'));
        }
        yield put(requestPublicServiceInfo(serviceId));
        const serviceInfoAction = yield take([
            PUBLIC_SERVICE_INFO_SUCCESS,
            PUBLIC_SERVICE_INFO_FAILURE,
        ]);
        if (serviceInfoAction.type === PUBLIC_SERVICE_INFO_FAILURE) {
            yield put(changeAppState(APP_STATE.INIT_FAILURE));
            yield put(newError(serviceInfoAction.errorId));
        } else {
            yield put(
                webRtcInit(
                    serviceInfoAction.publicServiceInfo.webRtcMode,
                    serviceInfoAction.publicServiceInfo.webRtcMaxAudioBitrate,
                    serviceInfoAction.publicServiceInfo.webRtcMaxVideoBitrate,
                    serviceInfoAction.publicServiceInfo.useSimulcast
                )
            );

            const initAction = yield take([
                WEBRTC_INIT_SUCCESS,
                WEBRTC_INIT_FAILURE,
            ]);
            if (initAction.type === WEBRTC_INIT_FAILURE) {
                yield put(changeAppState(APP_STATE.INIT_FAILURE));
                if (initAction.error.errorCode === 51) {
                    yield put(
                        newError(
                            webRtcErrorCodeToErrorId(
                                initAction.error.errorCode
                            ),
                            initAction.error.errorCode,
                            initAction.error.errorData
                        )
                    );
                } else {
                    yield put(
                        newError(
                            webRtcErrorCodeToErrorId(
                                initAction.error.errorCode
                            ),
                            initAction.error.errorCode
                        )
                    );
                }
            } else {
                const serviceId = serviceInfoAction.serviceId;
                yield put(alcInit(serviceId));
                yield take(ALC_INITIALIZED);
                if (Config.disclaimerEnabled) {
                    yield put(changeAppState(APP_STATE.DISCLAIMER));
                    yield take(CONFIRM_DISCLAIMER);
                }
                let search = window.location.search;
                let params = new URLSearchParams(search);
                let meetingId = params.get('id');
                if (!meetingId) {
                    yield put(changeAppState(APP_STATE.ENTER_MEETING_ID));
                } else {
                    yield put(getMeetingInfo(meetingId));
                }
            }
        }
    }
}

function* getMeetingInfoSaga() {
    while (true) {
        const { meetingId } = yield take(GET_MEETING_INFO);
        yield put(alcGetMeetingInfo(meetingId));
        while (true) {
            const meetingInfoAction = yield take([
                ALC_GET_MEETING_INFO_SUCCESS,
                ALC_ERROR,
            ]);
            if (meetingInfoAction.type === ALC_ERROR) {
                yield put(changeAppState(APP_STATE.ENTER_MEETING_ID));
                yield put(
                    newError(
                        alcErrorCodeToErrorId(meetingInfoAction.error.errorNo),
                        meetingInfoAction.error.errorNo
                    )
                );
                break;
            } else {
                break;
            }
        }
    }
}

export function* landingPageSagas() {
    yield all([initAppSaga(), getMeetingInfoSaga()]);
}
