import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MainVideoInitial from './MainVideoInitial';

export class MeetingMainVideo extends Component {
    constructor(props) {
        super(props);
        this.videoContainerRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.mainVideoView && this.props.mainVideoView.view) {
            this.videoContainerRef.current.appendChild(
                this.props.mainVideoView.view
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.mainVideoView !== this.props.mainVideoView) {
            if (prevProps.mainVideoView) {
                if (
                    prevProps.mainVideoView.view &&
                    this.videoContainerRef.current.contains(
                        prevProps.mainVideoView.view
                    )
                ) {
                    this.videoContainerRef.current.removeChild(
                        prevProps.mainVideoView.view
                    );
                }
            }
            if (this.props.mainVideoView && this.props.mainVideoView.view) {
                this.videoContainerRef.current.appendChild(
                    this.props.mainVideoView.view
                );
            }
        }
    }

    componentWillUnmount() {
        if (
            this.props.mainVideoView &&
            this.props.mainVideoView.view &&
            this.videoContainerRef.current.contains(
                this.props.mainVideoView.view
            )
        ) {
            this.videoContainerRef.current.removeChild(
                this.props.mainVideoView.view
            );
        }
    }

    render() {
        let placeholder = null;

        if (
            (!this.props.mainVideoView || !this.props.mainVideoView.view) &&
            this.props.mainVideoInitial
        ) {
            placeholder = (
                <MainVideoInitial
                    userId={this.props.mainVideoId}
                    initial={this.props.mainVideoInitial}
                />
            );
        }

        return (
            <div
                className={classNames('meeting-main-video', {
                    screenshare:
                        this.props.mainVideoView &&
                        this.props.mainVideoView.type === 'screen',
                })}
                ref={this.videoContainerRef}
            >
                {placeholder}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let mainVideoView;
    let mainVideoInitial;
    if (state.meetings.mainVideoId === state.meetings.ownVideoView.userId) {
        if (
            state.meetings.clientInfo.deviceInfo.screen &&
            state.meetings.ownScreenShareView
        ) {
            mainVideoView = state.meetings.ownScreenShareView;
        } else if (
            state.meetings.clientInfo.deviceInfo.video &&
            !state.deviceSettings.settingsPanelShown
        ) {
            mainVideoView = state.meetings.ownVideoView;
        }
        if (state.meetings.clientInfo.info.name) {
            let nameParts = state.meetings.clientInfo.info.name.split(' ');
            if (nameParts.length > 1 && nameParts[1] !== '') {
                mainVideoInitial =
                    nameParts[0].substr(0, 1) + nameParts[1].substr(0, 1);
            } else {
                mainVideoInitial = nameParts[0].substr(0, 2);
            }
        } else {
            mainVideoInitial = '';
        }
    } else {
        let screenShareOn = false;
        const meetingParticipant = state.meetings.meetingParticipants.find(
            (el) => el.id === state.meetings.mainVideoId
        );
        if (meetingParticipant) {
            if (meetingParticipant.info.name) {
                let nameParts = meetingParticipant.info.name.split(' ');
                if (nameParts.length > 1 && nameParts[1] !== '') {
                    mainVideoInitial =
                        nameParts[0].substr(0, 1) + nameParts[1].substr(0, 1);
                } else {
                    mainVideoInitial = nameParts[0].substr(0, 2);
                }
            } else {
                mainVideoInitial = '';
            }
            screenShareOn = meetingParticipant.deviceInfo.screen;
        }
        if (screenShareOn) {
            mainVideoView = state.meetings.screenShareViews.find(
                (el) => el.userId === state.meetings.mainVideoId
            );
        }
        if (
            !mainVideoView &&
            meetingParticipant &&
            meetingParticipant.deviceInfo.video
        ) {
            mainVideoView = state.meetings.videoViews.find(
                (el) => el.userId === state.meetings.mainVideoId
            );
        }
    }

    return {
        mainVideoView,
        mainVideoInitial,
        mainVideoId: state.meetings.mainVideoId,
    };
};

export default connect(mapStateToProps, undefined)(MeetingMainVideo);
