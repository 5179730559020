import React from 'react';
import { connect } from 'react-redux';
import { getTranslatedString } from './translations';

function TranslatedString(props) {
    return (
        <React.Fragment>
            {getTranslatedString(props.language, props.id)}
        </React.Fragment>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.base.i18n.language,
        id: ownProps.id
    };
};

export default connect(mapStateToProps, undefined)(TranslatedString);
