import favicon from '../assets/favicons/favicon_baloise.ico';

export const Config = {
    serviceUrl: 'https://al5-app03.adia.tv',
    serviceId: 'BALOISE-01',
    documentTitle: 'Baloise Meet',
    theme: 'baloise',

    favicon,
};
