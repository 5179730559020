import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TranslatedString from '../base/i18n/TranslatedString';
import {
    chatDocumentsIFrameNewRef,
    chatDocumentsIFrameLoad,
    hideChat
} from './actions';

export class ChatDocumentsPanel extends Component {
    constructor(props) {
        super(props);
        this.iFrameRefChatDocuments = React.createRef();
    }

    componentDidMount() {
        this.props.chatDocumentsIFrameNewRef(this.iFrameRefChatDocuments);
    }

    componentWillUnmount() {
        this.props.chatDocumentsIFrameNewRef(null);
    }

    render() {
        let sharedApplicationsUrl = this.props.sharedApplicationsUrl;
        if (this.props.saAuthToken) {
            if (sharedApplicationsUrl.indexOf('?') > -1) {
                sharedApplicationsUrl += '&jws=' + this.props.saAuthToken;
            } else {
                sharedApplicationsUrl += '?jws=' + this.props.saAuthToken;
            }
        }

        return (
            <div
                className={classNames('chat-documents-panel', {
                    hidden: !(
                        (this.props.chatPanelShown ||
                            this.props.documentsPanelShown) &&
                        this.props.chatDocumentsIFrameReady
                    ),
                    'documents-active': this.props.documentsPanelShown,
                    'chat-active': this.props.chatPanelShown,
                    controlsVisible: this.props.controlsVisible
                })}
            >
                <div className="p-grid p-justify-around">
                    <div className="p-col">
                        <h2>
                            <TranslatedString
                                id={
                                    this.props.chatPanelShown
                                        ? 'chat'
                                        : 'documents'
                                }
                            />
                        </h2>
                    </div>
                    <div
                        className="p-col-fixed"
                        style={{ width: '40px', paddingLeft: '25px' }}
                    >
                        <i
                            className="pi-md-close close-icon clickable"
                            onClick={this.props.hideChat}
                        />
                    </div>
                </div>
                <div className="chat-documents-iframe-container">
                    <iframe
                        className="chat-documents-iframe"
                        title="chat-documents iframe"
                        src={sharedApplicationsUrl}
                        ref={this.iFrameRefChatDocuments}
                        onLoad={this.props.chatDocumentsIFrameLoad}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        sharedApplicationsUrl:
            state.landingPage.publicServiceInfo.meetingsSettings
                .sharedApplicationsUrl,
        chatPanelShown: state.meetings.chatPanelShown,
        documentsPanelShown: state.meetings.documentsPanelShown,
        saAuthToken: state.meetings.saAuthToken,
        controlsVisible: state.meetings.controlsVisible,
        chatDocumentsIFrameReady: state.meetings.chatDocumentsIFrameReady
    };
};

const mapDispatchToProps = {
    chatDocumentsIFrameNewRef,
    chatDocumentsIFrameLoad,
    hideChat
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatDocumentsPanel);
