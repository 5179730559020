import * as types from './actionTypes';

export const initialState = {
    responsiveMode: 0
};

export default function common(state = initialState, action) {
    switch (action.type) {
        case types.RESPONSIVE_MODE_CHANGE: {
            return {
                ...state,
                responsiveMode: action.responsiveMode
            };
        }
        default:
            return state;
    }
}
