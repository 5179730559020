export const APP_STATE = {
    LOADING: 'LOADING',
    DISCLAIMER: 'DISCLAIMER',
    ENTER_MEETING_ID: 'ENTER_MEETING_ID',
    CONTACT_FORM: 'CONTACT_FORM',
    DISPLAY_MEETING_INFO: 'DISPLAY_MEETING_INFO',
    JOINING_MEETING: 'JOINING_MEETING',
    RECORDING_CONFIRMATION: 'RECORDING_CONFIRMATION',
    IN_MEETING: 'IN_MEETING',
    LEFT_MEETING: 'LEFT_MEETING',
    INIT_FAILURE: 'INIT_FAILURE',
};

export const SA_NAMESPACE = 'adiaLive';

export const SA_IDENTIFIERS = {
    MAIN: 'adiaLive.sharedApplications',
    DOCUMENTS: 'adiaLive.documents',
    CHAT: 'adiaLive.chat',
    CHAT_DOCUMENTS: 'adiaLive.chat-documents',
    MANAGER: 'adiaLive.preparation',
};

export const SA_APPLICATIONS = {
    AGENDA: 'agenda',
    SHOWBOARD: 'showboard',
    MINUTES: 'minutes',
    DOCUMENTS: 'documents',
    CHAT: 'chat',
};

export const IFRAME_COMMANDS = {
    INIT: 'init',
    INIT_CONFIRMED: 'initConfirmed',
    SHOW: 'show',
    HIDE: 'hide',
    READY: 'ready',
    SHOW_CONFIRMED: 'showConfirmed',
    CHAT_NEW_MESSAGE: 'newMessage',
    DOCUMENTS_NEW_UPLOAD: 'newDocument',
    JOIN_MEETING: 'joinMeeting',
    CHECK_COMPLETED: 'checkCompleted',
};

export const IFRAME_INIT_INTERVAL = 500;

export const RESOLUTION_LIST = [
    '480x270',
    '640x360',
    '1280x720',
    '1920x1080',
    '607x1080',
    '720x1280',
];

export const DEFAULT_RESOLUTION = '1280x720';

export const COMMUNICATION_MODES = {
    NONE: 'none',
    AUDIO: 'audio',
    VIDEO: 'video',
    BOTH: 'both',
};

export const WEBRTC_MODES = {
    LIVESWITCH_SFU: 'liveSwitchSfu',
    LIVESWITCH_P2P: 'liveSwitchPeer',
    ADIA_P2P: 'adiaP2P',
};

export const RESPONSIVE_MODES = {
    SMALL: 0,
    MEDIUM: 670,
    FULL: 780,
};

export const CONNECTION_QUALITY_THRESHOLDS = {
    GOOD: 80,
    MEDIUM: 60,
};

export const TOOLTIP_TOUCH_DELAY = 500;

export const ERRORS = {
    SERVER_UNREACHABLE_ERROR: {
        internalError: true,
        errorNo: 0,
        message: 'could not reach server',
    },
    PARSING_ERROR: {
        internalError: true,
        errorNo: 1,
        message: 'could not parse response',
    },
};

export const MEETING_TYPES = {
    MEETING: 1,
    WEBINAR: 2,
    PHONE_CONSULTING: 4,
};

export const RECORDING_CONFIRMATION_STATE = {
    PENDING: 0,
    CONFIRMED: 1,
    DECLINED: 2,
};

export const SHOWBOARD_TOOLBAR_HEIGHT = 64;
