import favicon from '../assets/favicons/favicon_swisscom.ico';
import { MEETING_TYPES } from '../constants/constants';

export const Config = {
    serviceUrl: 'https://al5-app06.adia.tv',
    serviceId: 'Swisscom-Academy',
    documentTitle: 'Swisscom Academy',
    theme: 'swisscom-academy',
    favicon,
    communicatorType: MEETING_TYPES.WEBINAR,
};
