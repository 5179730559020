import { defaultConfig } from './Config_default';

/*
import Config_CONFIGNAME.js
if no CONFIGNAME is defined, use Config_local.js
  */

let config_name = 'local';

// TODO: better way of matching urls?
if (window.location.href.includes('dev-meet.adia.tv')) {
    config_name = 'al5-dev01_DEFAULT';
} else if (window.location.href.includes('int-meet.adia.tv')) {
    config_name = 'al5-int01_DEFAULT';
} else if (window.location.href.includes('meet.baloise.ch')) {
    config_name = 'al5-app03_BALOISE-01';
} else if (window.location.href.includes('meet.finfire.de')) {
    config_name = 'al5-app04_Finfire';
} else if (window.location.href.includes('meet.adialive.ch')) {
    config_name = 'al5-app06_DEFAULT';
} else if (window.location.href.includes('swisscomacademy.adialive.ch')) {
    config_name = 'al5-app06_Swisscom-Academy';
} else if (window.location.href.includes('onlineberatungpro.de')) {
    config_name = 'al5-app07_OnlineberatungPro';
} else if (window.location.href.includes('live.blkb.ch')) {
    config_name = 'al5-app08_BLKB-01_meet';
} else if (window.location.href.includes('live-telefon.blkb.ch')) {
    config_name = 'al5-app08_BLKB-01_phone';
} else if (window.location.href.includes('live-beratung.vema-eg.de')) {
    config_name = 'al5-app09_VEMA';
} else if (window.location.href.includes('guest.adialive.ch')) {
    config_name = 'al5-app10_DEFAULT';
} else if (window.location.href.includes('guest-del.adialive.ch')) {
    config_name = 'al5-app10-del_DEFAULT';
} else if (window.location.href.includes('guest-uc.adialive.ch')) {
    config_name = 'al5-app10-uc_DEFAULT';
} else if (
    window.location.href.includes('videoberatung.vzch.com') ||
    window.location.href.includes('conseil-video.vzch.com') ||
    window.location.href.includes('videoconsulenza.vzch.com') ||
    window.location.href.includes('videoconsulting.vzch.com')
) {
    config_name = 'VZ-PROD_VZ-CH-01';
} else if (
    window.location.href.includes('videoberatung.vzde.com') ||
    window.location.href.includes('videoconsulting.vzde.com')
) {
    config_name = 'VZ-PROD_VZ-DE-01';
} else if (
    window.location.href.includes('videoberatung-int.vzch.com') ||
    window.location.href.includes('conseil-video-int.vzch.com') ||
    window.location.href.includes('videoconsulenza-int.vzch.com') ||
    window.location.href.includes('videoconsulting-int.vzch.com')
) {
    config_name = 'VZ-INT_VZ-CH-01';
} else if (
    window.location.href.includes('videoberatung-int.vzde.com') ||
    window.location.href.includes('videoconsulting-int.vzde.com')
) {
    config_name = 'VZ-INT_VZ-DE-01';
}

let config = null;

try {
    config = require(`./Config_${config_name}.js`).Config;
} catch (err) {
    throw new Error("Can't find config file Config_" + config_name + '.js !');
}

config.name = config_name;
export const Config = Object.assign(defaultConfig, config);
