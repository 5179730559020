import favicon from '../assets/favicons/favicon_blkb.ico';
import landingPageImage from '../assets/images/icon-chat-blkb.svg';
import {
    disclaimerContent,
    disclaimerLabel,
} from './disclaimer/Disclaimer_BLKB';

export const Config = {
    serviceUrl: 'https://al5-app08.adia.tv',
    serviceId: 'BLKB-01',
    documentTitle: 'BLKB Live',
    theme: 'blkb',

    favicon,
    landingPageImage,
    disclaimerEnabled: true,
    disclaimerContent,
    disclaimerLabel,
    availableLanguages: ['de'],
    hideEmailInput: true,
    nameInputOptional: true,

    languageOverrides: {
        de: {
            meetingTitle: '',
            consultationTitle: '',
            yourMeetingId: '',
            yourConsultationId: '',
            meetingCompleted:
                'Die Beratung ist nun beendet, vielen Dank für das Gespräch',
            consultationCompleted:
                'Die Beratung ist nun beendet, vielen Dank für das Gespräch',
        },
    },
};
