export const INIT_APP = 'INIT_APP';
export const CHANGE_APP_STATE = 'CHANGE_APP_STATE';
export const EDIT_PARTICIPANT = 'EDIT_PARTICIPANT';
export const NEW_ERROR = 'NEW_ERROR';
export const GET_MEETING_INFO = 'GET_MEETING_INFO';
export const CONFIRM_DISCLAIMER = 'CONFIRM_DISCLAIMER';

export const PUBLIC_SERVICE_INFO_REQUEST = 'PUBLIC_SERVICE_INFO_REQUEST';
export const PUBLIC_SERVICE_INFO_SUCCESS = 'PUBLIC_SERVICE_INFO_SUCCESS';
export const PUBLIC_SERVICE_INFO_FAILURE = 'PUBLIC_SERVICE_INFO_FAILURE';

export const CONTACT_FORM_REQUEST = 'CONTACT_FORM_REQUEST';
export const CONTACT_FORM_REQUEST_SUCCESS = 'CONTACT_FORM_REQUEST_SUCCESS';
export const CONTACT_FORM_REQUEST_FAILURE = 'CONTACT_FORM_REQUEST_FAILURE';

export const ALC_GET_MEETING_INFO = 'ALC_GET_MEETING_INFO';
export const ALC_GET_MEETING_INFO_SUCCESS = 'ALC_GET_MEETING_INFO_SUCCESS';
