import { take, put, all, select } from 'redux-saga/effects';

import {
    JOIN_MEETING,
    LEAVE_MEETING,
    ALC_JOIN_MEETING_SUCCESS,
    ALC_ERROR,
    WEBRTC_JOIN_SUCCESS,
    WEBRTC_START_LOCAL_MEDIA_SUCCESS,
    WEBRTC_START_LOCAL_MEDIA_FAILURE,
    WEBRTC_JOIN_FAILURE,
    WEBRTC_INIT_SUCCESS,
    ALC_INITIALIZED,
    ALC_KICKED,
    ALC_SESSION_RESTORED,
    ALC_RECONNECTING,
    WEBRTC_RECONNECTING_FAILURE,
    ALC_MEETING_COMPLETED,
    ALC_CONFIRM_RECORDING,
} from './actionTypes';
import {
    alcClose,
    alcJoinMeeting,
    alcLeaveMeeting,
    webRtcClose,
    webRtcJoin,
    webRtcLeave,
    joinMeetingSuccess,
    joinMeetingFailure,
    webRtcStartLocalMedia,
    webRtcStopLocalMedia,
} from './actions';
import { changeAppState, newError } from '../landingPage/actions';
import {
    showSettings,
    webRtcChangeCommunicationMode,
} from '../deviceSettings/actions';
import {
    alcErrorCodeToErrorId,
    webRtcErrorCodeToErrorId,
} from '../base/util/helpers';
import {
    APP_STATE,
    COMMUNICATION_MODES,
    MEETING_TYPES,
} from '../../constants/constants';
import { WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS } from '../deviceSettings/actionTypes';

const getAlcMeetingJoined = (state) => state.meetings.alcMeetingJoined;
const getWebRtcMeetingJoined = (state) => state.meetings.webRtcMeetingJoined;
const getAlcInitialized = (state) => state.meetings.alcInitialized;
const getWebRtcInitialized = (state) => state.meetings.webRtcInitialized;
const getCommunicationMode = (state) => state.deviceSettings.communicationMode;
const getMeetingType = (state) => state.landingPage.meetingInfo.type;

function* joinMeeting() {
    while (true) {
        const { meetingId, participantName, email } = yield take(JOIN_MEETING);
        const webRtcInitialized = yield select(getWebRtcInitialized);
        // wait until webRtc layer is initialized
        if (!webRtcInitialized) {
            yield take(WEBRTC_INIT_SUCCESS);
        }
        const alcInitialized = yield select(getAlcInitialized);
        // wait until alc initialized
        if (!alcInitialized) {
            yield take(ALC_INITIALIZED);
        }
        // set communication mode to none when joining a webinar or phoneConsulting
        const type = yield select(getMeetingType);
        if (
            type === MEETING_TYPES.WEBINAR ||
            type === MEETING_TYPES.PHONE_CONSULTING
        ) {
            yield put(webRtcChangeCommunicationMode(COMMUNICATION_MODES.NONE));
            yield take(WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS);
        }
        // try to start local media first
        yield put(webRtcStartLocalMedia());
        const startLocalMediaAction = yield take([
            WEBRTC_START_LOCAL_MEDIA_SUCCESS,
            WEBRTC_START_LOCAL_MEDIA_FAILURE,
        ]);
        if (startLocalMediaAction.type === WEBRTC_START_LOCAL_MEDIA_SUCCESS) {
            // if local media could be started, join alc meeting
            yield put(
                alcJoinMeeting(
                    meetingId,
                    participantName,
                    email,
                    startLocalMediaAction.communicationMode
                )
            );
            while (true) {
                let alcJoinAction = yield take([
                    ALC_JOIN_MEETING_SUCCESS,
                    ALC_ERROR,
                ]);
                // if recording is running and confirmation is needed, ask user and join again
                if (
                    alcJoinAction.type === ALC_ERROR &&
                    alcJoinAction.context === 'joinMeeting' &&
                    alcJoinAction.error.errorNo === 40104
                ) {
                    const confirmRecordingAction = yield take(
                        ALC_CONFIRM_RECORDING
                    );
                    if (confirmRecordingAction.confirmed === true) {
                        yield put(
                            alcJoinMeeting(
                                meetingId,
                                participantName,
                                email,
                                startLocalMediaAction.communicationMode,
                                true
                            )
                        );
                        while (true) {
                            alcJoinAction = yield take([
                                ALC_JOIN_MEETING_SUCCESS,
                                ALC_ERROR,
                            ]);
                            if (
                                alcJoinAction.type ===
                                    ALC_JOIN_MEETING_SUCCESS ||
                                alcJoinAction.context === 'joinMeeting'
                            ) {
                                break;
                            }
                        }
                    }
                }
                if (alcJoinAction.type === ALC_JOIN_MEETING_SUCCESS) {
                    // if alc meeting is joined, join webRtc meeting
                    yield put(
                        webRtcJoin(
                            alcJoinAction.joinOptions,
                            alcJoinAction.meetingInfo
                        )
                    );
                    const webRtcJoinAction = yield take([
                        WEBRTC_JOIN_SUCCESS,
                        WEBRTC_JOIN_FAILURE,
                    ]);
                    if (webRtcJoinAction.type === WEBRTC_JOIN_SUCCESS) {
                        yield put(joinMeetingSuccess());
                    } else {
                        yield put(joinMeetingFailure());
                        yield put(alcLeaveMeeting());
                        let errorCode;
                        if (webRtcJoinAction.error) {
                            errorCode = webRtcJoinAction.error.errorCode;
                        }
                        yield put(
                            changeAppState(APP_STATE.DISPLAY_MEETING_INFO)
                        );
                        yield put(
                            newError(
                                webRtcErrorCodeToErrorId(errorCode),
                                errorCode
                            )
                        );
                    }
                    break;
                } else {
                    // TODO: account for additional errors that can occur on joinMeeting
                    if (alcJoinAction.context === 'joinMeeting') {
                        yield put(joinMeetingFailure());
                        yield put(webRtcStopLocalMedia());
                        // TODO: change app state based on error (-enter meeting id / show meeting info)
                        yield put(
                            changeAppState(APP_STATE.DISPLAY_MEETING_INFO)
                        );
                        yield put(
                            newError(
                                alcErrorCodeToErrorId(
                                    alcJoinAction.error.errorNo
                                ),
                                alcJoinAction.error.errorNo
                            )
                        );
                        break;
                    }
                }
            }
        } else {
            // if local media couldn't be started
            const communicationMode = yield select(getCommunicationMode);
            yield put(showSettings(communicationMode));
            yield put(changeAppState(APP_STATE.DISPLAY_MEETING_INFO));
        }
    }
}

function* leaveMeeting() {
    while (true) {
        const leaveAction = yield take([
            LEAVE_MEETING,
            ALC_KICKED,
            ALC_MEETING_COMPLETED,
            WEBRTC_RECONNECTING_FAILURE,
        ]);
        // leave webRtc meeting if joined
        const webRtcMeetingJoined = yield select(getWebRtcMeetingJoined);
        if (webRtcMeetingJoined) {
            yield put(webRtcLeave());
        }
        // leave alc meeting if joined
        const alcMeetingJoined = yield select(getAlcMeetingJoined);
        if (alcMeetingJoined) {
            yield put(alcLeaveMeeting());
        }
        const webRtcInitialized = yield select(getWebRtcInitialized);
        if (webRtcInitialized) {
            yield put(webRtcClose());
        }
        const alcInitialized = yield select(getAlcInitialized);
        if (alcInitialized) {
            yield put(alcClose());
        }
        if (leaveAction.type === WEBRTC_RECONNECTING_FAILURE) {
            yield put(changeAppState(APP_STATE.DISPLAY_MEETING_INFO));
            yield put(newError('alcErrorMeetingConnectionLost'));
        } else {
            yield put(changeAppState(APP_STATE.LEFT_MEETING));
            if (leaveAction.type === ALC_KICKED) {
                yield put(newError('msgKick'));
            }
        }
    }
}

function* reconnecting() {
    while (true) {
        yield take(ALC_RECONNECTING);
        const alcMeetingJoined = yield select(getAlcMeetingJoined);
        const action = yield take([ALC_SESSION_RESTORED, ALC_INITIALIZED]);
        if (action.type === ALC_INITIALIZED) {
            const webRtcMeetingJoined = yield select(getWebRtcMeetingJoined);
            if (webRtcMeetingJoined) {
                yield put(webRtcLeave());
            }
            if (alcMeetingJoined) {
                yield put(changeAppState(APP_STATE.DISPLAY_MEETING_INFO));
                yield put(newError('alcErrorMeetingConnectionLost'));
            }
        }
    }
}

export function* meetingsSagas() {
    yield all([joinMeeting(), leaveMeeting(), reconnecting()]);
}
