import React, { Component } from 'react';
import classNames from 'classnames';

import ConnectionIndicator from './ConnectionIndicator';
import AudioLevelIndicator from './AudioLevelIndicator';
import { RESPONSIVE_MODES } from '../../constants/constants';

export default class VideoBox extends Component {
    constructor(props) {
        super(props);
        this.videoContainerRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.videoView && this.props.videoView.view) {
            this.videoContainerRef.current.appendChild(
                this.props.videoView.view
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.videoView !== this.props.videoView) {
            if (prevProps.videoView && prevProps.videoView.view) {
                if (
                    this.videoContainerRef.current.contains(
                        prevProps.videoView.view
                    )
                ) {
                    this.videoContainerRef.current.removeChild(
                        prevProps.videoView.view
                    );
                }
            }
            if (this.props.videoView && this.props.videoView.view) {
                this.videoContainerRef.current.appendChild(
                    this.props.videoView.view
                );
            }
        }
    }

    componentWillUnmount() {
        if (this.props.videoView && this.props.videoView.view) {
            if (
                this.videoContainerRef.current.contains(
                    this.props.videoView.view
                )
            ) {
                this.videoContainerRef.current.removeChild(
                    this.props.videoView.view
                );
            }
        }
    }

    render() {
        const hasVideo = this.props.videoView && this.props.videoView.view;

        let placeholder = null;
        if (!hasVideo) {
            if (this.props.isMainVideo) {
                if (this.props.isCurrentActiveUser) {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                } else if (this.props.isActiveSpeaker) {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                } else {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                }
            } else {
                let nameParts;
                if (this.props.userInfo.info.name) {
                    nameParts = this.props.userInfo.info.name.split(' ');
                }
                let initals = '';
                if (nameParts) {
                    if (nameParts.length > 1 && nameParts[1] !== '') {
                        initals =
                            nameParts[0].substr(0, 1) +
                            nameParts[1].substr(0, 1);
                    } else {
                        initals = nameParts[0].substr(0, 2);
                    }
                }
                placeholder = (
                    <div className="video-list-icon">
                        <div className="name-initial">{initals}</div>
                    </div>
                );
            }
        }
        if (this.props.responsiveMode === RESPONSIVE_MODES.SMALL) {
            return (
                <div className="mobile-video-box">
                    <div
                        className="mobile-video-container"
                        ref={this.videoContainerRef}
                    />
                </div>
            );
        } else {
            return (
                <div
                    className={classNames('video-list-box', {
                        'video-box-active': this.props.isCurrentActiveUser,
                    })}
                >
                    <div className="participant-name">
                        {this.props.userInfo.info.name}
                    </div>
                    <ConnectionIndicator
                        userInfo={this.props.userInfo}
                        videoActive={this.props.userInfo.deviceInfo.video}
                        isSelf={this.props.isSelf}
                    />
                    <div
                        className="video-container"
                        ref={this.videoContainerRef}
                    >
                        {placeholder}
                    </div>
                    <AudioLevelIndicator userId={this.props.userInfo.id} />
                    {!this.props.userInfo.deviceInfo.audio && (
                        <div className="muted-icon">
                            <i className="icon-mic-disabled" />
                        </div>
                    )}
                </div>
            );
        }
    }
}
