import favicon from '../assets/favicons/favicon_adiacom.ico';
import landingPageImage from '../assets/images/icon-chat.svg';
import { MEETING_TYPES } from '../constants/constants';

export const defaultConfig = {
    favicon,
    documentTitle: 'adiaLive Meet',
    landingPageImage,
    hideTitleResponsiveSmall: false,
    hideEmailInput: false,
    nameInputOptional: false,
    disclaimerEnabled: false,
    serviceIdFromUrl: false,
    communicatorType: MEETING_TYPES.MEETING,
};
