export const english = {
    // General
    language: 'Language',
    de: 'German',
    en: 'English',
    fr: 'French',
    it: 'Italian',

    save: 'Save',
    cancel: 'Cancel',
    confirm: 'Confirm',
    decline: 'Decline',
    close: 'Close',
    refresh: 'Refresh',

    reconnectingHeader: 'Reconnecting',
    reconnectingMessage: 'Lost Connection to Server. Trying to reconnect...',

    // Device Settings

    deviceSettings: 'Settings',
    cam: 'Camera',
    mic: 'Microphone',
    resolution: 'Resolution',
    comModeNone: 'Chat only',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + Video',

    // Meeting

    agenda: 'Agenda',
    showboard: 'Showboard',
    minutes: 'Minutes',
    documents: 'Documents',
    chat: 'Chat',
    show: 'Show',

    startFullscreen: 'Start Full Screen',
    stopFullscreen: 'Exit Full Screen',
    startScreenShare: 'Share your Screen',
    stopScreenShare: 'Stop sharing your Screen',
    leaveMeeting: 'Leave Meeting',
    muteMic: 'Mute Microphone',
    unmuteMic: 'Unmute Microphone',
    activateMic: 'Activate Microphone',
    startCam: 'Start Camera',
    stopCam: 'Stop Camera',
    activateCam: 'Activate Camera',
    meetingInfo: 'Meeting Info',
    sharedApplications: 'Shared Applications',
    menu: 'Menu',

    msgRemoteMute: 'You have been muted by an Adviser',
    msgKick: 'You have been removed from the meeting by an Adviser',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Recording

    recConfirmTitle: 'Confirm recording',
    recConfirmMessage: "We'd like to record this meeting. Do you agree?",

    // Errors

    connectionError: 'Connection to server failed.',
    serviceNotFound: 'This service does not exist.',

    alcErrorDefault: 'alc Error',
    alcErrorMeetingDoesNotExist:
        'This meeting has been completed or does not exist.',
    alcErrorMeetingConnectionLost:
        'Lost connection to Meeting Server. Please try again.',
    alcErrorMeetingFull:
        'Maximum number of Participants reached for this meeting.',
    alcErrorWebinarParticipantsFull:
        'Maximum number of Participants reached for this webinar.',
    alcErrorNeedsRecordingConfirmation:
        'You can only join this meeting if you agree to the recording.',

    webRtcErrorDefault: 'WebRTC Error',
    webRtcErrorNotFound:
        'No Camera / Microphone found. Please make sure that your Camera / Microphone is plugged in and click Refresh.',
    webRtcErrorNotAllowed:
        'Please allow access to Devices in your Browser. Activate Audio / Audio + Video afterwards.',
    webRtcErrorNotReadable:
        'Your Camera is blocked by another application. Please close it or select a different Device.',
    webRtcErrorOverconstrained:
        'Your saved Camera / Microphone could not be found. Please check that your Camera / Microphone is plugged in and click Refresh or select a different Device.',
    webRtcErrorConnectionTimeout: 'Timeout trying to connect to meeting server',

    webRtcBrowserNotSupported:
        'Your Browser is not supported. Please use a recent version of Chrome or Firefox.',
    webRtcBrowserOutOfDate:
        'Your {{browser}} Version is ouf of date and is no longer supported. Please update your Browser.',
    webRtcBrowserNonSafariIos:
        'Unfortunately {{browser}} is not supported on iOS. Please try a recent version of Safari.',
    webRtcBrowserLegacyEdge:
        "You're using Edge Legacy. This version is out of date and is no longer supported. We suggest using the new Edge which you can download {{link}}.",
    webRtcBrowserIe:
        'Internet Explorer is not supported. We suggest using the new Edge which you can download {{link}}.',

    // Communicator

    yourName: 'Your Name',
    yourEmail: 'Your E-Mail Address',
    invalidEmail: 'E-Mail Address invalid',
    enterNameAndEmail: 'Please enter Name and E-Mail Address',
    continue: 'Continue',

    meetingTitle: 'Meeting',
    meetingCompleted: 'Meeting completed',
    yourMeetingId: 'Your Meeting-ID',
    yourMeeting: 'Your Meeting',
    enterMeetingId: 'Please enter your Meeting-ID.',
    meetingId: 'Meeting-ID',
    joinMeeting: 'Join Meeting',
    meetingNotReady:
        'Your appointment will take place on {{date}} at {{time}}. Please wait or try again later.',
    meetingExpired:
        'This meeting is no longer valid. Please make a new appointment.',

    webinarTitle: 'Webinar',
    webinarCompleted: 'Webinar completed',
    yourWebinarId: 'Your Webinar-ID',
    yourWebinar: 'Your Webinar',
    enterWebinarId: 'Please enter your Webinar-ID',
    webinarId: 'Webinar-ID',
    joinWebinar: 'Join Webinar',
    webinarNotReady:
        'The Webinar will take place on {{date}} at {{time}}. Please wait or try again later.',
    webinarExpired: 'This Webinar is no longer valid.',

    consultationTitle: 'Consultation',
    consultationCompleted: 'Consultation completed',
    yourConsultationId: 'Your Consultation-ID',
    yourConsultation: 'Your Consultation',
    enterConsultationId: 'Please enter your Consultation-ID',
    consultationId: 'Consultation-ID',
    joinConsultation: 'Join Consultation',
    consultationNotReady:
        'The Consultation will take place on {{date}} at {{time}}. Please wait or try again later.',
    consultationExpired: 'This Consultation is no longer valid.',

    // Webinars
    participants: 'Participants',
    requestSpeakerPermission: 'Request Speaking Permission',
    cancelRequestSpeakerPermission: 'Cancel Requesting Speaking Permission',
    endSpeakerPermission: 'Terminate Speaking Permission',
    speakerPermission: 'Speaking Permission',
    speakerPermissionConfirmation:
        "You've been granted permission to speak. Please choose your desired media.",
    leaveWebinar: 'Leave Webinar',

    // Contact Form
    contactForm: 'Contact Form',
    contactFormText: 'Or send us your contact information',
    contactFormSuccess:
        "Thanks for contacting us. We've received your contact information and will get in touch with you.",
    send: 'Send',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    message: 'Message',
    desiredDate: 'Desired Date',
};
