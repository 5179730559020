import * as types from './actionTypes';

export function initApp() {
    return { type: types.INIT_APP };
}

export function changeAppState(appState) {
    return { type: types.CHANGE_APP_STATE, appState };
}

export function editParticipant() {
    return { type: types.EDIT_PARTICIPANT };
}

export function newError(errorId, errorNo, errorData) {
    return { type: types.NEW_ERROR, errorId, errorNo, errorData };
}

export function getMeetingInfo(meetingId) {
    return { type: types.GET_MEETING_INFO, meetingId };
}

export function confirmDisclaimer() {
    return { type: types.CONFIRM_DISCLAIMER };
}

function getPublicServiceInfoRequest() {
    return { type: types.PUBLIC_SERVICE_INFO_REQUEST };
}

function getPublicServiceInfoSuccess(serviceId, publicServiceInfo) {
    return {
        type: types.PUBLIC_SERVICE_INFO_SUCCESS,
        serviceId,
        publicServiceInfo,
    };
}

function getPublicServiceInfoFailure(errorId) {
    return { type: types.PUBLIC_SERVICE_INFO_FAILURE, errorId };
}

/*
 * thunk
 */
export function requestPublicServiceInfo(serviceId) {
    return async (dispatch, getState, api) => {
        dispatch(getPublicServiceInfoRequest());

        const response = await api.getPublicServiceInfo(serviceId);
        if (!response.error) {
            dispatch(
                getPublicServiceInfoSuccess(
                    serviceId,
                    response.publicServiceInfo
                )
            );
        } else {
            const errorId =
                response.error.errorNo === 40400
                    ? 'serviceNotFound'
                    : 'connectionError';
            dispatch(getPublicServiceInfoFailure(errorId));
        }
    };
}

export function alcGetMeetingInfoSuccess(
    meetingInfo,
    participantInfo,
    serverTime
) {
    return {
        type: types.ALC_GET_MEETING_INFO_SUCCESS,
        meetingInfo,
        participantInfo,
        serverTime,
    };
}

export function alcGetMeetingInfo(meetingId) {
    return { type: types.ALC_GET_MEETING_INFO, meetingId };
}

function contactFormRequest() {
    return { type: types.CONTACT_FORM_REQUEST };
}

function contactFormRequestSuccess() {
    return { type: types.CONTACT_FORM_REQUEST_SUCCESS };
}

function contactFormRequestFailure(errorId) {
    return { type: types.CONTACT_FORM_REQUEST_FAILURE, errorId };
}

/*
 * thunk
 */
export function sendContactForm(name, email, phone, message, desiredDate) {
    return async (dispatch, getState, api) => {
        dispatch(contactFormRequest());

        const serviceId = getState().landingPage.publicServiceInfo
            ? getState().landingPage.publicServiceInfo._id
            : null;
        if (serviceId) {
            const contactRequestData = {
                name,
                email,
                phone,
                message,
                desiredDate,
            };

            const response = await api.createContactRequest(
                serviceId,
                contactRequestData
            );
            if (!response.error) {
                dispatch(contactFormRequestSuccess());
            } else {
                const errorId =
                    response.error.errorNo === 40400
                        ? 'serviceNotFound'
                        : 'connectionError';
                dispatch(contactFormRequestFailure(errorId));
            }
        } else {
            contactFormRequestFailure('serviceNotFound');
        }
    };
}
