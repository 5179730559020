import favicon from '../assets/favicons/favicon_ritter.ico';

export const Config = {
    serviceUrl: 'https://al5-app07.adia.tv',
    serviceIdFromUrl: true,
    documentTitle: 'Onlineberatung Pro',
    theme: 'onlineberatung-pro',

    favicon,
};
