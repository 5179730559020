import favicon from '../assets/favicons/favicon_vema.ico';

export const Config = {
    serviceUrl: 'https://al5-vema.adia.tv',
    serviceIdFromUrl: true,
    documentTitle: 'VEMA Beratung',
    theme: 'vema',

    favicon,
};
