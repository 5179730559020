import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import {
    sharedApplicationsIFrameNewRef,
    sharedApplicationsIFrameLoad,
    switchOwnSharedApplication,
    alcSetCurrentApp,
    alcSetEditPermissions,
    hideSharedApplications,
} from './actions';
import { getTranslatedString } from '../base/i18n/translations';
import { SHOWBOARD_TOOLBAR_HEIGHT } from '../../constants/constants';

export class SharedApplicationsPanel extends Component {
    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);

        this.containerRef = React.createRef();
        this.headerRef = React.createRef();

        this.iFrameRef = React.createRef();

        this.state = {
            bodyHeight: 0,
            bodyWidth: 0,
        };

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.props.sharedApplicationsIFrameNewRef(this.iFrameRef);

        /* content sizing */
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.sharedApplicationsShown !==
                this.props.sharedApplicationsShown ||
            prevProps.controlsVisible !== this.props.controlsVisible ||
            prevProps.saIFrameReady !== this.props.saIFrameReady
        ) {
            this.handleResize();
        }
    }

    componentWillUnmount() {
        this.props.sharedApplicationsIFrameNewRef(null);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if (this.props.sharedApplicationsShown || this.props.standalone) {
            const mainContainerRect = this.containerRef.current.getBoundingClientRect();
            const headerContainerRect = this.headerRef.current.getBoundingClientRect();

            let bodyTargetHeight =
                mainContainerRect.height - headerContainerRect.height;
            let bodyTargetWidth =
                ((bodyTargetHeight - SHOWBOARD_TOOLBAR_HEIGHT) / 9) * 16;
            if (bodyTargetWidth > mainContainerRect.width) {
                bodyTargetWidth = mainContainerRect.width;
                bodyTargetHeight =
                    (bodyTargetWidth / 16) * 9 + SHOWBOARD_TOOLBAR_HEIGHT;
            }
            this.setState({
                bodyHeight: bodyTargetHeight,
                bodyWidth: bodyTargetWidth,
            });
        }
    }

    isShowActiveOnCurrentPanel() {
        return (
            this.props.meetingInfo.currentApp &&
            (!this.props.ownApplicationId ||
                this.props.meetingInfo.currentApp ===
                    this.props.ownApplicationId)
        );
    }

    render() {
        const agendaItem = {
            label: getTranslatedString(this.props.language, 'agenda'),
            icon: 'pi-md-format-list-bulleted',
            appId: 'agenda',
            className: classNames({
                'active-sa-tab': this.props.meetingInfo.currentApp === 'agenda',
            }),
        };

        const showboardItem = {
            label: getTranslatedString(this.props.language, 'showboard'),
            icon: 'pi-md-laptop',
            appId: 'showboard',
            className: classNames({
                'active-sa-tab':
                    this.props.meetingInfo.currentApp === 'showboard',
            }),
        };

        const minutesItem = {
            label: getTranslatedString(this.props.language, 'minutes'),
            icon: 'pi-md-speaker-notes',
            appId: 'minutes',
            className: classNames({
                'active-sa-tab':
                    this.props.meetingInfo.currentApp === 'minutes',
            }),
        };

        const currentShownApp = this.props.meetingInfo.currentApp;

        let activeItem = null;
        switch (currentShownApp) {
            case 'agenda': {
                activeItem = agendaItem;
                break;
            }
            case 'showboard': {
                activeItem = showboardItem;
                break;
            }
            case 'minutes': {
                activeItem = minutesItem;
                break;
            }
            default: {
                activeItem = agendaItem;
                break;
            }
        }

        const items = [activeItem];

        let sharedApplicationsUrl = this.props.sharedApplicationsUrl;
        if (this.props.saAuthToken) {
            if (sharedApplicationsUrl.indexOf('?') > -1) {
                sharedApplicationsUrl += '&jws=' + this.props.saAuthToken;
            } else {
                sharedApplicationsUrl += '?jws=' + this.props.saAuthToken;
            }
        }

        return (
            <div
                className={classNames({
                    'sa-panel-container': true,
                    hidden:
                        !this.props.sharedApplicationsShown ||
                        !this.props.saIFrameReady,
                    controlsVisible: this.props.controlsVisible,
                })}
                ref={this.containerRef}
            >
                <div className="sa-panel">
                    <div className="sa-panel-header" ref={this.headerRef}>
                        <TabMenu
                            model={items}
                            activeItem={activeItem}
                            onTabChange={() => {}}
                        />
                    </div>
                    <div
                        className="sa-panel-body"
                        style={{
                            height: this.state.bodyHeight,
                            width: this.state.bodyWidth,
                        }}
                    >
                        <iframe
                            className="shared-applications-iframe"
                            title="shared application iframe"
                            src={sharedApplicationsUrl}
                            ref={this.iFrameRef}
                            onLoad={this.props.sharedApplicationsIFrameLoad}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedApplicationsShown: state.meetings.sharedApplicationsShown,
        sharedApplicationsUrl:
            state.landingPage.publicServiceInfo.meetingsSettings
                .sharedApplicationsUrl,
        ownApplicationId: state.meetings.ownApplicationId,
        meetingInfo: state.meetings.meetingInfo,
        meetingParticipants: state.meetings.meetingParticipants,
        saAuthToken: state.meetings.saAuthToken,
        controlsVisible: state.meetings.controlsVisible,
        saIFrameReady: state.meetings.saIFrameReady,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    sharedApplicationsIFrameNewRef,
    sharedApplicationsIFrameLoad,
    switchOwnSharedApplication,
    alcSetCurrentApp,
    alcSetEditPermissions,
    hideSharedApplications,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SharedApplicationsPanel);
