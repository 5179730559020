import React, { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import { Dialog } from 'primereact/dialog';
import { Helmet } from 'react-helmet';

import './serenity/ripple';

import { APP_STATE, RESPONSIVE_MODES } from './constants/constants';
import { LoadingScreen } from './features/base/loadingScreen/LoadingScreen';
import LandingPage from './features/landingPage/LandingPage';
import Meeting from './features/meetings/Meeting';
import DeviceSettings from './features/deviceSettings/DeviceSettings';
import CustomGrowl from './features/base/messages/CustomGrowl';
import TranslatedString from './features/base/i18n/TranslatedString';
import { initApp } from './features/landingPage/actions';
import { changeResponsiveMode } from './features/base/common/actions';
import { Config } from './config/Config';

class Communicator extends Component {
    constructor(props) {
        super(props);

        this.handleWindowResizeEvent = this.handleWindowResizeEvent.bind(this);
        this.handleWindowResizeEventThrottled = throttle(
            this.handleWindowResizeEvent,
            100
        );
    }

    componentDidMount() {
        this.props.initApp();

        window.addEventListener(
            'resize',
            this.handleWindowResizeEventThrottled
        );
        this.handleWindowResizeEvent();
    }

    componentWillUnmount() {
        window.removeEventListener(
            'resize',
            this.handleWindowResizeEventThrottled
        );
    }

    handleWindowResizeEvent() {
        let newWidth = document.body.clientWidth;
        let newResponsiveMode = 0;
        if (newWidth < RESPONSIVE_MODES.MEDIUM) {
            newResponsiveMode = RESPONSIVE_MODES.SMALL;
        } else if (newWidth < RESPONSIVE_MODES.FULL) {
            newResponsiveMode = RESPONSIVE_MODES.MEDIUM;
        } else {
            newResponsiveMode = RESPONSIVE_MODES.FULL;
        }

        if (this.props.responsiveMode !== newResponsiveMode) {
            this.props.changeResponsiveMode(newResponsiveMode);
        }
    }

    render() {
        const reconnectingDialog = (
            <Dialog
                header={<TranslatedString id="reconnectingHeader" />}
                visible={this.props.alcReconnecting}
                closable={false}
                onHide={() => {}}
                baseZIndex={999999}
            >
                <div className="p-grid">
                    <div className="p-col-12">
                        <TranslatedString id="reconnectingMessage" />
                    </div>
                    <div className="p-col-12 reconnecting-spinner-container">
                        <LoadingScreen />
                    </div>
                </div>
            </Dialog>
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>{Config.documentTitle}</title>
                    {Config.favicon && (
                        <link rel="shortcut icon" href={Config.favicon} />
                    )}
                </Helmet>
                {this.renderContent()}
                {this.props.settingsPanelShown && <DeviceSettings />}
                {reconnectingDialog}
            </React.Fragment>
        );
    }

    renderContent() {
        switch (this.props.appState) {
            case APP_STATE.LOADING: {
                return <LoadingScreen />;
            }
            case APP_STATE.ENTER_MEETING_ID:
            case APP_STATE.DISPLAY_MEETING_INFO:
            case APP_STATE.LEFT_MEETING:
            case APP_STATE.INIT_FAILURE:
            case APP_STATE.DISCLAIMER:
            case APP_STATE.CONTACT_FORM: {
                return <LandingPage />;
            }
            case APP_STATE.JOINING_MEETING:
            case APP_STATE.IN_MEETING:
            case APP_STATE.RECORDING_CONFIRMATION: {
                return (
                    <React.Fragment>
                        <Meeting />
                        <CustomGrowl topbarHidden />
                    </React.Fragment>
                );
            }
            default: {
                // TODO: implement error page?
                return <div>UNKNOWN APP STATE</div>;
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        appState: state.landingPage.appState,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        alcReconnecting: state.meetings.alcReconnecting,
        responsiveMode: state.base.common.responsiveMode,
    };
};

const mapDispatchToProps = {
    initApp,
    changeResponsiveMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Communicator);
