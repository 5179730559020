import { combineReducers } from 'redux';
import meetings from './features/meetings/reducer';
import landingPage from './features/landingPage/reducer';
import deviceSettings from './features/deviceSettings/reducer';

import common from './features/base/common/reducer';
import i18n from './features/base/i18n/reducer';
import messages from './features/base/messages/reducer';

const base = combineReducers({ common, i18n, messages });

const rootReducer = combineReducers({
    base,
    meetings,
    landingPage,
    deviceSettings
});

export default rootReducer;
