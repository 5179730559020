import React, { Component } from 'react';
import { Menu } from 'primereact/menu';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

import {
    hideChat,
    hideDocuments,
    hideSharedApplications,
    hideMenu,
    leaveMeeting,
    showChat,
    showDocuments,
    showMenu,
    webRtcStartScreenShare,
    webRtcStopScreenShare,
    fullscreenToggle,
    alcRequestSpeakerPermission,
    revokeSpeakerPermission,
} from './actions';
import {
    hideSettings,
    showSettings,
    webRtcMuteAudio,
    webRtcMuteVideo,
} from '../deviceSettings/actions';
import { connect } from 'react-redux';
import TranslatedString from '../base/i18n/TranslatedString';
import {
    COMMUNICATION_MODES,
    MEETING_TYPES,
    RESPONSIVE_MODES,
    TOOLTIP_TOUCH_DELAY,
} from '../../constants/constants';
import { fullscreenEnabled } from '../base/util/helpers';
import classNames from 'classnames';

export class MeetingToolbar extends Component {
    constructor(props) {
        super(props);
        this.handleActivateCamera = this.handleActivateCamera.bind(this);
        this.handleActivateMic = this.handleActivateMic.bind(this);
        this.handleRaiseHand = this.handleRaiseHand.bind(this);
    }

    handleActivateCamera() {
        if (
            this.props.communicationMode === COMMUNICATION_MODES.AUDIO ||
            this.props.communicationMode === COMMUNICATION_MODES.NONE
        ) {
            this.props.showSettings();
        } else {
            this.props.webRtcMuteVideo(false);
        }
    }

    handleActivateMic() {
        if (this.props.communicationMode === COMMUNICATION_MODES.NONE) {
            this.props.showSettings();
        } else {
            this.props.webRtcMuteAudio(false);
        }
    }

    handleRaiseHand() {
        if (this.props.speakerPermission) {
            this.props.revokeSpeakerPermission();
        } else if (this.props.requestedSpeakerPermission) {
            this.props.alcRequestSpeakerPermission(false);
        } else {
            this.props.alcRequestSpeakerPermission(true);
        }
    }

    render() {
        const responsiveMenuActive =
            this.props.responsiveMode < RESPONSIVE_MODES.MEDIUM;

        const micInactive =
            this.props.communicationMode === COMMUNICATION_MODES.NONE;

        const isWebinar = this.props.meetingInfo.type === MEETING_TYPES.WEBINAR;

        const audioMuteButton =
            !isWebinar || !micInactive ? (
                <div aria-label="Toggle mute audio" className="toolbox-button">
                    <div className="toolbox-icon">
                        {this.props.audioMuted || micInactive ? (
                            <Tippy
                                content={
                                    micInactive ? (
                                        <TranslatedString id="activateMic" />
                                    ) : (
                                        <TranslatedString id="unmuteMic" />
                                    )
                                }
                                arrow
                                touch={['hold', TOOLTIP_TOUCH_DELAY]}
                            >
                                <i
                                    className="icon-mic-disabled toggled"
                                    onClick={this.handleActivateMic}
                                />
                            </Tippy>
                        ) : (
                            <Tippy
                                content={<TranslatedString id="muteMic" />}
                                arrow
                                touch={['hold', TOOLTIP_TOUCH_DELAY]}
                            >
                                <i
                                    className="icon-microphone"
                                    onClick={() =>
                                        this.props.webRtcMuteAudio(true)
                                    }
                                />
                            </Tippy>
                        )}
                    </div>
                </div>
            ) : null;

        const leaveMeetingButton = (
            <div aria-label="Leave the call" className="toolbox-button">
                <div className="toolbox-icon">
                    <Tippy
                        content={
                            <TranslatedString
                                id={isWebinar ? 'leaveWebinar' : 'leaveMeeting'}
                            />
                        }
                        arrow
                        touch={['hold', TOOLTIP_TOUCH_DELAY]}
                    >
                        <i
                            className="icon-hangup"
                            onClick={this.props.leaveMeeting}
                        />
                    </Tippy>
                </div>
            </div>
        );

        const cameraInactive =
            this.props.communicationMode === COMMUNICATION_MODES.AUDIO ||
            this.props.communicationMode === COMMUNICATION_MODES.NONE;

        const videoMuteButton =
            !isWebinar || !cameraInactive ? (
                <div aria-label="Toggle mute video" className="toolbox-button">
                    <div className="toolbox-icon">
                        {this.props.videoMuted || cameraInactive ? (
                            <Tippy
                                content={
                                    cameraInactive ? (
                                        <TranslatedString id="activateCam" />
                                    ) : (
                                        <TranslatedString id="startCam" />
                                    )
                                }
                                arrow
                                touch={['hold', TOOLTIP_TOUCH_DELAY]}
                            >
                                <i
                                    className="icon-camera-disabled toggled"
                                    onClick={this.handleActivateCamera}
                                />
                            </Tippy>
                        ) : (
                            <Tippy
                                content={<TranslatedString id="stopCam" />}
                                arrow
                                touch={['hold', TOOLTIP_TOUCH_DELAY]}
                            >
                                <i
                                    className="icon-camera"
                                    onClick={() =>
                                        this.props.webRtcMuteVideo(true)
                                    }
                                />
                            </Tippy>
                        )}
                    </div>
                </div>
            ) : null;

        let menuItems = [];

        if (responsiveMenuActive) {
            if (this.props.hasDisplayMedia && !isWebinar) {
                if (this.props.ownScreenShareView) {
                    menuItems.push({
                        label: <TranslatedString id="stopScreenShare" />,
                        icon: 'pi pi-fw pi-md-desktop-windows',
                        command: () => {
                            this.props.webRtcStopScreenShare();
                            this.props.hideMenu();
                        },
                    });
                } else {
                    menuItems.push({
                        label: <TranslatedString id="startScreenShare" />,
                        icon: 'pi pi-fw pi-md-desktop-windows',
                        command: () => {
                            this.props.webRtcStartScreenShare();
                            this.props.hideMenu();
                        },
                    });
                }
            }
            if (this.props.sharedApplicationsUrl) {
                menuItems.push({
                    label: <TranslatedString id="chat" />,
                    icon: 'pi pi-fw pi-md-chat-bubble-outline',
                    command: this.props.chatPanelShown
                        ? () => {
                              this.props.hideChat();
                              this.props.hideMenu();
                          }
                        : () => {
                              this.props.showChat();
                              this.props.hideMenu();
                          },
                    disabled: !this.props.chatDocumentsIFrameReady,
                    className: this.props.chatMessagePending
                        ? 'item-pending'
                        : null,
                });
                if (!this.props.disableCommunicatorFileTransfer) {
                    menuItems.push({
                        label: <TranslatedString id="documents" />,
                        icon: 'pi pi-fw pi-md-insert-drive-file',
                        command: this.props.documentsPanelShown
                            ? () => {
                                  this.props.hideDocuments();
                                  this.props.hideMenu();
                              }
                            : () => {
                                  this.props.showDocuments();
                                  this.props.hideMenu();
                              },
                        disabled: !this.props.chatDocumentsIFrameReady,
                        className: this.props.documentPending
                            ? 'item-pending'
                            : null,
                    });
                }
            }
            if (!isWebinar || !micInactive) {
                menuItems.push({
                    label: <TranslatedString id="deviceSettings" />,
                    icon: 'pi pi-fw pi-md-settings',
                    command: () => {
                        this.props.showSettings();
                        this.props.hideMenu();
                    },
                });
            }
        }

        const fullscreenButton = fullscreenEnabled ? (
            <div aria-label="Toggle fullscreen" className="toolbox-button">
                <div className="toolbox-icon">
                    {this.props.fullscreenActive ? (
                        <Tippy
                            content={<TranslatedString id="stopFullscreen" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-full-screen toggled "
                                onClick={this.props.fullscreenToggle}
                            />
                        </Tippy>
                    ) : (
                        <Tippy
                            content={<TranslatedString id="startFullscreen" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-full-screen"
                                onClick={this.props.fullscreenToggle}
                            />
                        </Tippy>
                    )}
                </div>
            </div>
        ) : null;

        const screenShareButton =
            this.props.hasDisplayMedia &&
            !isWebinar &&
            !responsiveMenuActive ? (
                <div aria-label="Toggle screenshare" className="toolbox-button">
                    <div className="toolbox-icon">
                        {this.props.ownScreenShareView ? (
                            <Tippy
                                content={
                                    <TranslatedString id="stopScreenShare" />
                                }
                                arrow
                                touch={['hold', TOOLTIP_TOUCH_DELAY]}
                            >
                                <i
                                    className="icon-share-desktop toggled "
                                    onClick={this.props.webRtcStopScreenShare}
                                />
                            </Tippy>
                        ) : (
                            <Tippy
                                content={
                                    <TranslatedString id="startScreenShare" />
                                }
                                arrow
                                touch={['hold', TOOLTIP_TOUCH_DELAY]}
                            >
                                <i
                                    className="icon-share-desktop"
                                    onClick={this.props.webRtcStartScreenShare}
                                />
                            </Tippy>
                        )}
                    </div>
                </div>
            ) : null;

        const chatButton =
            this.props.sharedApplicationsUrl && !responsiveMenuActive ? (
                <div aria-label="Toggle chat" className="toolbox-button">
                    <div className="toolbox-icon">
                        <Tippy
                            content={<TranslatedString id="chat" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            {this.props.chatPanelShown ? (
                                <i
                                    className="icon-chat toggled"
                                    onClick={this.props.hideChat}
                                />
                            ) : (
                                <i
                                    className={classNames('icon-chat', {
                                        disabled: !this.props
                                            .chatDocumentsIFrameReady,
                                    })}
                                    onClick={
                                        this.props.chatDocumentsIFrameReady
                                            ? this.props.showChat
                                            : null
                                    }
                                />
                            )}
                        </Tippy>
                    </div>
                    {this.props.chatMessagePending && (
                        <span className="toolbox-badge">
                            <span className="badge-round">N</span>
                        </span>
                    )}
                </div>
            ) : null;

        const documentsButton =
            this.props.sharedApplicationsUrl &&
            !this.props.disableCommunicatorFileTransfer &&
            !responsiveMenuActive ? (
                <div aria-label="Toggle Documents" className="toolbox-button">
                    <div className="toolbox-icon">
                        <Tippy
                            content={<TranslatedString id="documents" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            {this.props.documentsPanelShown ? (
                                <i
                                    className="icon-share-doc toggled"
                                    onClick={this.props.hideDocuments}
                                />
                            ) : (
                                <i
                                    className={classNames('icon-share-doc', {
                                        disabled: !this.props
                                            .chatDocumentsIFrameReady,
                                    })}
                                    onClick={
                                        this.props.chatDocumentsIFrameReady
                                            ? this.props.showDocuments
                                            : null
                                    }
                                />
                            )}
                        </Tippy>
                        {this.props.documentPending && (
                            <span className="toolbox-badge">
                                <span className="badge-round">N</span>
                            </span>
                        )}
                    </div>
                </div>
            ) : null;

        const settingsButton =
            !responsiveMenuActive && (!isWebinar || !micInactive) ? (
                <div aria-label="Open Settings" className="toolbox-button">
                    <div className="toolbox-icon">
                        <Tippy
                            content={<TranslatedString id="deviceSettings" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            {this.props.settingsPanelShown ? (
                                <i
                                    className="icon-settings toggled"
                                    onClick={this.props.hideSettings}
                                />
                            ) : (
                                <i
                                    className="icon-settings"
                                    onClick={() => this.props.showSettings()}
                                />
                            )}
                        </Tippy>
                    </div>
                </div>
            ) : null;

        const menuButton =
            responsiveMenuActive && menuItems.length > 0 ? (
                <div aria-label="More" className="toolbox-button">
                    <div className="toolbox-icon">
                        {this.props.menuVisible ? (
                            <i
                                className="icon-thumb-menu toggled"
                                onClick={this.props.hideMenu}
                            />
                        ) : (
                            <Tippy
                                content={<TranslatedString id="menu" />}
                                arrow
                                touch={['hold', TOOLTIP_TOUCH_DELAY]}
                            >
                                <i
                                    className="icon-thumb-menu"
                                    onClick={this.props.showMenu}
                                />
                            </Tippy>
                        )}
                    </div>
                    {responsiveMenuActive &&
                        (this.props.chatMessagePending ||
                            (this.props.documentPending &&
                                !this.props
                                    .disableCommunicatorFileTransfer)) && (
                            <span className="toolbox-badge">
                                <span className="badge-round">N</span>
                            </span>
                        )}
                </div>
            ) : null;

        let raisedHandTextId = 'requestSpeakerPermission';
        if (this.props.speakerPermission) {
            raisedHandTextId = 'endSpeakerPermission';
        } else if (this.props.requestedSpeakerPermission) {
            raisedHandTextId = 'cancelRequestSpeakerPermission';
        }

        const raiseHandButton = isWebinar ? (
            <div aria-label="Raise Hand" className="toolbox-button">
                <div className="toolbox-icon">
                    <Tippy
                        content={<TranslatedString id={raisedHandTextId} />}
                        arrow
                        touch={['hold', TOOLTIP_TOUCH_DELAY]}
                    >
                        <i
                            className={classNames('icon-raised-hand', {
                                toggled:
                                    this.props.requestedSpeakerPermission ||
                                    this.props.speakerPermission,
                            })}
                            onClick={this.handleRaiseHand}
                        />
                    </Tippy>
                </div>
            </div>
        ) : null;

        return (
            <div
                className={
                    'meeting-toolbar' +
                    (this.props.controlsVisible ? ' visible' : '')
                }
            >
                <div className="toolbox-background" />
                <div className="toolbox-menu-container">
                    {this.props.menuVisible && <Menu model={menuItems} />}
                </div>
                <div className="toolbox-content">
                    <div className="button-group-left">
                        {fullscreenButton}
                        {screenShareButton}
                        {chatButton}
                        {documentsButton}
                    </div>
                    <div className="button-group-center">
                        {audioMuteButton}
                        {leaveMeetingButton}
                        {videoMuteButton}
                    </div>
                    <div className={'button-group-right'}>
                        {settingsButton}
                        {raiseHandButton}
                        {menuButton}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.base.i18n.language,
        clientInfo: state.meetings.clientInfo,
        meetingInfo: state.meetings.meetingInfo,
        meetingParticipants: state.meetings.meetingParticipants,
        audioMuted: state.deviceSettings.currentSettings.audioMuted,
        videoMuted: state.deviceSettings.currentSettings.videoMuted,
        ownScreenShareView: state.meetings.ownScreenShareView,
        documentsPanelShown: state.meetings.documentsPanelShown,
        chatPanelShown: state.meetings.chatPanelShown,
        chatMessagePending: state.meetings.chatMessagePending,
        documentPending: state.meetings.documentPending,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        controlsVisible: state.meetings.controlsVisible,
        menuVisible: state.meetings.menuVisible,
        communicationMode: state.deviceSettings.communicationMode,
        sharedApplicationsUrl:
            state.landingPage.publicServiceInfo.meetingsSettings
                .sharedApplicationsUrl,
        hasDisplayMedia: state.meetings.webRtcFlags.hasDisplayMedia,
        responsiveMode: state.base.common.responsiveMode,
        fullscreenActive: state.meetings.fullscreenActive,
        chatDocumentsIFrameReady: state.meetings.chatDocumentsIFrameReady,
        disableCommunicatorFileTransfer:
            state.landingPage.publicServiceInfo.meetingsSettings
                .disableCommunicatorFileTransfer,
        speakerPermission: state.meetings.clientInfo.speakerPermission,
        requestedSpeakerPermission:
            state.meetings.clientInfo.requestedSpeakerPermission,
    };
};

const mapDispatchToProps = {
    hideSettings,
    showSettings,
    hideChat,
    hideDocuments,
    hideSharedApplications,
    hideMenu,
    leaveMeeting,
    showChat,
    showDocuments,
    showMenu,
    webRtcMuteAudio,
    webRtcMuteVideo,
    webRtcStartScreenShare,
    webRtcStopScreenShare,
    fullscreenToggle,
    alcRequestSpeakerPermission,
    revokeSpeakerPermission,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingToolbar);
